import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingPage from './content/LandingPage/LandingPage';
import Header from './components/Header/Header';

const App = () => (
  <>
    <Header />
    <Switch>
      <Route exact path="/" component={LandingPage} />
    </Switch>
  </>
);

export default App;
