import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function LandingPage() {
  return (
    <>
      <Typography variant="h1" component="div" gutterBottom>
        JackRobinsonUK
      </Typography>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography />
        </CardContent>
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
    </>
  );
}
